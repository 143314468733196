import { Modal, Button, Checkbox, Spin, message } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import Flex from "../../../helpers/components/Flex";
import { useCommonTitlesStore, useAuthStore } from "../../../store/useStores";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableBody, TableHead } from "@mui/material";
import { FieldModal } from "./FieldModal";
import axios from "axios";
import apiGetProjectModules from "../../../api/endpoints/apiGetProjectModules";
import { TitlesModal } from "./TitlesModal";
import displayResponseMessage from "../../../helpers/functions/displayResponseMessage";
import ProjectModulesSearch from "./helpers/ProjectModulesSearch";
import filterProjectModulesOnSearch from "./helpers/filterProjectModulesOnSearch";

const ProjectModulesModalContent = ({
  modules,
  setModules,
  allModules,
  headers,
  updateProjectModules,
  allChecked,
  selectAll,
  projectID,
  fetchDataAgain,
  project,
}) => {
  const [fieldsModalVisible, setFieldsModalVisible] = useState(false);
  const [titlesModalVisible, setTitlesModalVisible] = useState(false);
  const [moduleID, setModuleID] = useState(null);
  const baseURL = useAuthStore((state) => state.baseURL);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const saveFieldRights = async (projectID, moduleID, fields) => {
    const url = `${baseURL}/Project_module_fields_api/save`;

    let fieldsIncluded = {};
    fields.forEach((item) => {
      if (item.ACTIVE) {
        fieldsIncluded = {
          ...fieldsIncluded,
          [item.ID]: item.PROJECTFIELDTITLE,
        };
      }
    });

    try {
      const response = await axios.post(
        url,
        { projectID: projectID, moduleid: moduleID, fields: fieldsIncluded },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Project_module_fields_api/save: response: ", response.data);

      if (response.data.STATUS === "Success") {
        fetchDataAgain();
        setFieldsModalVisible(false);
      } else {
        message(response.data.MESSAGE, [3]);
      }
    } catch (err) {}
  };
  const cancelFieldModal = () => {
    setFieldsModalVisible(false);
  };

  const cancelTitlesModal = () => setTitlesModalVisible(false);

  const saveTitles = async (changes) => {
    const url = `${baseURL}/App_titles2_api/save`;

    try {
      const response = await axios.post(
        url,
        { ...changes },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("App_titles2_api/save: response: ", response?.data);

      if (response?.data?.STATUS === "Success") {
        setTitlesModalVisible(false);
      } else {
        const responseMsg = response?.data?.MESSAGE ?? "";
        //message.warning(responseMsg, [5]);
        displayResponseMessage(responseMsg, 5, "warning");
      }
    } catch (err) {
      //message.error(err, [3]);
      displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
    }
  };

  const updateSearchValue = (change) => {
    const tempData = filterProjectModulesOnSearch(allModules, change, headers);

    if (change) {
      setModules([...tempData]);
    } else {
      setModules(allModules);
    }
  };

  return (
    <Flex
      flexDirection={"column"}
      style={{ marginTop: "2rem", marginBottom: "2rem" }}
    >
      <h3 style={{ display: "flex", justifyContent: "center", gap: "0.5rem" }}>
        <p>{commonTitles["CO|1|PROJECT"]}:</p>
        <p>{project}</p>
      </h3>
      <Flex
        flexDirection={"row"}
        style={{ width: "100%", justifyContent: "flex-end", marginTop: 15 }}
      >
        <ProjectModulesSearch updateSearchValue={updateSearchValue} />
      </Flex>
      <TableContainer
        component={Paper}
        style={{ maxHeight: "60vh", marginTop: "2rem" }}
        className="testing_table"
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headers?.map((header, index) => (
                <TableCell
                  style={{ fontWeight: 700, color: "rgb(8, 8, 46)" }}
                  key={header.id}
                >
                  <Flex
                    flexDirection={"row"}
                    style={{
                      gap: "0.5rem",
                      justifyContent: index > 0 ? "center" : "left",
                    }}
                  >
                    {index === 1 && (
                      <Checkbox
                        checked={allChecked}
                        onChange={(e) => selectAll(e.target.checked)}
                      />
                    )}
                    {header.title}
                  </Flex>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {modules?.map((item) => (
              <TableRow key={item.ID}>
                <TableCell>{item.TITLE}</TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={item.ACTIVE}
                    onChange={(e) =>
                      updateProjectModules(item.ID, "ACTIVE", e.target.checked)
                    }
                  />
                </TableCell>
                <TableCell align="center">
                  {item.ACTIVE && (
                    <Button
                      shape="round"
                      className="dropdown"
                      onClick={() => {
                        setTitlesModalVisible(true);
                        setModuleID(item.ID);
                      }}
                      style={{
                        backgroundColor: "rgb(8, 8, 46)",
                        color: "#fff",
                      }}
                    >
                      {commonTitles["CO|1|TITLES"]}
                    </Button>
                  )}
                </TableCell>
                <TableCell align="center">
                  {item.ACTIVE && (
                    <Button
                      shape="round"
                      className="dropdown"
                      onClick={() => {
                        setFieldsModalVisible(true);
                        setModuleID(item.ID);
                      }}
                      style={{
                        backgroundColor: "rgb(8, 8, 46)",
                        color: "#fff",
                      }}
                    >
                      {commonTitles["CO|1|FLDPLR"]}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
            {fieldsModalVisible && (
              <FieldModal
                visible={fieldsModalVisible}
                title={commonTitles["CO|1|FLDPLR"]}
                onSave={saveFieldRights}
                onCancel={cancelFieldModal}
                projectID={projectID}
                moduleID={moduleID}
                project={project}
                module={
                  modules.filter((item) => item.ID === moduleID)[0]?.TITLE ?? ""
                }
              />
            )}
            {titlesModalVisible && (
              <TitlesModal
                visible={titlesModalVisible}
                moduleID={moduleID}
                onSave={saveTitles}
                onCancel={cancelTitlesModal}
                project={project}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Flex>
  );
};

export const ProjectModulesModal = ({
  visible,
  title,
  onSave,
  onCancel,
  projectID,
  project,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const baseURL = useAuthStore((state) => state.baseURL);
  const language = useAuthStore((state) => state.language);
  const [modules, setModules] = useState([]);
  const [allModules, setAllModules] = useState([]);
  const [titles, setTitles] = useState({});
  const [headers, setHeaders] = useState([]);
  const [fetchAgain, setFetchAgain] = useState(false);
  const [allChecked, setAllChecked] = useState(false);

  useEffect(async () => {
    const { modules, moduletitles } = await apiGetProjectModules(
      baseURL,
      projectID
    );
    setModules(modules);
    setAllModules(modules);
    setTitles(moduletitles);
    setHeaders([
      { id: "TITLE", title: moduletitles["FD|277|TITLE"] },
      { id: "ACTIVE", title: moduletitles["FD|278|TITLE"] },
      { id: "PROJECT_TITLES", title: commonTitles["CO|17|TITLES"] },
      { id: "PROJECTFIELDS", title: commonTitles["CO|17|FIELDS"] },
    ]);

    let includeAll = true;

    modules.forEach((item) => {
      if (!item.ACTIVE) {
        includeAll = false;
      }
    });
    setAllChecked(includeAll);
  }, [language, projectID, fetchAgain]);

  const fetchDataAgain = () => setFetchAgain(!fetchAgain);

  const updateProjectModules = (moduleID, id, value) => {
    const newState = modules.map((item) => {
      if (item.ID === moduleID) {
        return { ...item, [id]: value };
      }

      return item;
    });

    setModules(newState);
  };

  const selectAll = (value) => {
    const newState = modules.map((item) => {
      return { ...item, ACTIVE: value };
    });

    setModules(newState);
    setAllChecked(value);
  };

  return (
    <Modal
      key={`userRoleRights-modal`}
      mask={false}
      maskClosable={false}
      open={visible}
      style={{
        minWidth: "60vw",
      }}
      bodyStyle={{ paddingLeft: 80, paddingRight: 80 }}
      title={title}
      onOk={() => onSave(projectID, modules)}
      onCancel={() => onCancel()}
      footer={[
        <Button key="back" onClick={() => onCancel()}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,
        <Button
          key="submit"
          type="primary"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          onClick={() => onSave(projectID, modules)}
        >
          {commonTitles["CO|1|SAVEBTN"]}
        </Button>,
      ]}
    >
      <ProjectModulesModalContent
        modules={modules}
        setModules={setModules}
        allModules={allModules}
        headers={headers}
        updateProjectModules={updateProjectModules}
        allChecked={allChecked}
        selectAll={selectAll}
        projectID={projectID}
        project={project}
        fetchDataAgain={fetchDataAgain}
      />
    </Modal>
  );
};
