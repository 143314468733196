const config = (set) => ({
  baseURL: "https://www.plcs.site",

  coreChange: false,
  setCoreChange: (newCoreChange) => set(() => ({ coreChange: newCoreChange })),

  language: "EL",
  setLanguage: (newLanguage) => set(() => ({ language: newLanguage })),

  userID: "",
  setUserID: (newUserID) => set(() => ({ userID: newUserID })),

  projectID: "",
  setProjectID: (newProjectID) => set(() => ({ projectID: newProjectID })),

  workspaceID: "",
  setWorkspaceID: (newWorkspaceID) =>
    set(() => ({ workspaceID: newWorkspaceID })),

  username: "",
  setUsername: (newUsername) => set(() => ({ username: newUsername })),

  password: "",
  setPassword: (newPassword) => set(() => ({ password: newPassword })),

  firstName: "",
  setFirstName: (newFirstName) => set(() => ({ firstName: newFirstName })),

  lastName: "",
  setLastName: (newLastName) => set(() => ({ lastName: newLastName })),

  role: "",
  setRole: (newRole) => set(() => ({ role: newRole })),

  roleID: "",
  setRoleID: (newRoleID) => set(() => ({ roleID: newRoleID })),

  dateFormat: "",
  setDateFormat: (newDateFormat) => set(() => ({ dateFormat: newDateFormat })),

  timeFormat: "",
  setTimeFormat: (newTimeFormat) => set(() => ({ timeFormat: newTimeFormat })),

  loginStatus: "",
  setLoginStatus: (newLoginStatus) =>
    set(() => ({ loginStatus: newLoginStatus })),

  showGroup: "",
  setShowGroup: (newShowGroup) => set(() => ({ showGroup: newShowGroup })),

  darkTheme: true,
  setDarkTheme: (newDarkTheme) => set(() => ({ darkTheme: newDarkTheme })),

  navigationColor: "#02081b",
  setNavigationColor: (newNavigationColor) =>
    set(() => ({ navigationColor: newNavigationColor })),

  bannerColor: "rgb(8, 8, 46)",
  setBannerColor: (newBannerColor) =>
    set(() => ({ bannerColor: newBannerColor })),

  navigationTextColor: "rgb(255, 255, 255)",
  setNavigationTextColor: (newNavigationTextColor) =>
    set(() => ({ navigationTextColor: newNavigationTextColor })),

  bannerTextColor: "rgb(255, 255, 255)",
  setBannerTextColor: (newBannerTextColor) =>
    set(() => ({ bannerTextColor: newBannerTextColor })),

  bannerPathColor: "rgb(84, 190, 243)",
  setBannerPathColor: (newBannerPathColor) =>
    set(() => ({ bannerPathColor: newBannerPathColor })),
});

const AuthStore = {
  config,
  shouldLog: true,
};

export default AuthStore;
