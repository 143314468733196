import { useAuthStore, useCommonTitlesStore } from "../../../store/useStores";
import Flex from "../../../helpers/components/Flex";
import SearchIcon from "@mui/icons-material/Search";
import { ConfigProvider, Input } from "antd";

const UserRolesRightsSearch = ({ updateSearchValue }) => {
  const bannerTextColor = useAuthStore((state) => state.bannerTextColor);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const style = {
    flex: 1,
    justifyContent: "flex-end",
    marginRight: "2rem",
    color: "black",
    alignItems: "center",
    marginLeft: "-2rem",
  };

  const iconStyle = {
    marginLeft: "2rem",
    marginBottom: "0.3rem",
    color: "black",
  };

  const inputStyle = {
    borderRadius: 15,
    border: `1px solid black`,
    background: "transparent",
    lineHeight: 40,
    paddingLeft: 20,
    fontSize: 13,
    marginLeft: "0.5rem",
    width: 250,
    marginRight: -20,
    maxWidth: 250,
    color: "black",
    height: 25,
    marginTop: -6,
  };

  return (
    <Flex flexDirection={"row"} style={style}>
      <SearchIcon style={iconStyle} />
      <ConfigProvider
        theme={{
          token: {
            colorTextPlaceholder:
              bannerTextColor.replace(")", ", 0.75)").replace("rgb", "rgba") ??
              "rgb(255, 255, 255)",
          },
        }}
      >
        <Input
          style={inputStyle}
          placeholder={`${commonTitles.search}...`}
          onChange={(e) => updateSearchValue(e.target.value)}
        />
      </ConfigProvider>
    </Flex>
  );
};

export default UserRolesRightsSearch;
