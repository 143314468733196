const createData = (
  rows = {},
  fields = [],
  fkvalues = {},
  staticoptions = {},
  baseURL,
  dateTypes = []
) => {
  let newRows = [rows];
  let dataValues = [];
  let dataKeys = [];

  if (newRows.length !== 0) {
    dataValues = Object.values(newRows[0]);
    dataKeys = Object.keys(newRows[0]);
  }

  let staticFields = [];
  let foreignFields = [];
  let multipleForeignFields = [];
  let attachmentFields = [];
  let dependentFields = [];
  let renderColorDateFields = [];

  fields.forEach((item) => {
    if (item.F_TYPE === "6") {
      staticFields.push(item.F_ID);
    }
    if (item.F_TYPE === "7") {
      foreignFields.push(item.F_ID);
    }
    if (item.F_TYPE === "28") {
      multipleForeignFields.push(item.F_ID);
    }
    if (item.F_TYPE === "24" || item.F_TYPE === "25") {
      attachmentFields.push(item.F_ID);
    }
    if (item.F_TYPE === "30") {
      dependentFields.push(item.F_ID);
    }
    if (dateTypes.includes(item.F_TYPE) && item?.F_RENDER === "ALERT") {
      renderColorDateFields.push(item.F_ID);
    }
  });

  let tempData = [];

  dataValues.forEach((item) => {
    let tempRow = {};
    let rowID = item.ROWID;
    Object.keys(item).forEach((key) => {
      if (attachmentFields.includes(key)) {
        let attachmentArray = [];
        if (item[key].length) {
          item[key].map((attachment) => {
            attachmentArray.push({
              uid: attachment.ID,
              name: attachment.DN,
              status: "done",
              url: `${baseURL}/attachments/${attachment.FN}`,
            });
          });
          tempRow = { ...tempRow, [key]: attachmentArray };
        } else {
          tempRow = { ...tempRow, [key]: [] };
        }
      } else if (staticFields.includes(key)) {
        let newValue = staticoptions[key][item[key].DV]?.TITLE;
        let bgColor = staticoptions[key][item[key].DV]?.BC;
        let order = staticoptions[key][item[key].DV]?.ORDER;
        tempRow = {
          ...tempRow,
          [key]: {
            view: newValue,
            bgColor: bgColor,
            order: order,
          },
          [`${key}-server`]: item[key].DV,
          [`${key}-US`]: item[key].US,
        };
      } else if (foreignFields.includes(key)) {
        let newValue = fkvalues[key][item[key].DV]?.view;
        tempRow = {
          ...tempRow,
          [key]: newValue,
          [`${key}-server`]: item[key].DV,
          [`${key}-US`]: item[key].US,
        };
      } else if (multipleForeignFields.includes(key)) {
        let newValue = item[key].DV?.split(",") ?? [];
        newValue.sort();
        var str = "";
        var serverString = "";
        newValue
          .filter((item) => item !== "")
          .forEach((item) => {
            if (str !== "") {
              str += ", ";
            }
            str += fkvalues[key][item]?.view;

            if (serverString !== "") {
              serverString += ",";
            }
            serverString += item;
          });
        tempRow = {
          ...tempRow,
          [key]: str,
          [`${key}-server`]: serverString,
          [`${key}-US`]: item[key].US,
        };
      } else if (dependentFields.includes(key)) {
        const parentFieldID = fields
          .filter((field) => field.F_ID === key)
          .map((item) => item.F_PARENTFIELDID);

        const DVArray =
          rows[rowID][parentFieldID]?.DV.split(",").filter((item) => item) ??
          [];

        let str = "";
        let serverStr = "";
        DVArray.map((item) => {
          if (str !== "") {
            str += ", ";
          }
          str += fkvalues[key]?.[item]?.view ?? "";

          if (serverStr !== "") {
            serverStr += ",";
          }
          serverStr += item;
        });

        const finalValue = str;
        const finalServerValue = serverStr;

        tempRow = {
          ...tempRow,
          [key]: finalValue,
          [`${key}-server`]: finalServerValue,
          [`${key}-US`]: item[key].US,
        };
      } else if (renderColorDateFields.includes(key)) {
        tempRow = {
          ...tempRow,
          [key]: {
            value: item[key].DV,
            color: item[key]?.COL ?? ["#ffffff", "B"],
          },
        };
      } else if (key === "LM") {
        tempRow = { ...tempRow, [key]: item[key] };
      } else if (key === "ROWID") {
        tempRow = { ...tempRow, [key]: item[key] };
      } else {
        tempRow = {
          ...tempRow,
          [key]: item[key].DV,
          [`${key}-US`]: item[key].US,
        };
      }
    });
    tempData.push(tempRow);
  });

  let modifiedData = {};

  tempData.forEach((item) => {
    modifiedData = { ...modifiedData, [item?.ROWID]: item };
  });

  // let tempData = [];
  // for (let i = 0; i < dataValues.length; i++) {
  //   for (const [key, value] of Object.entries(dataValues[i])) {
  //     let rowID = dataValues[i].ROWID;
  //     if (attachmentFields.includes(key)) {
  //       let attachmentArray = [];
  //       if (value.length) {
  //         value.map((attachment) => {
  //           attachmentArray.push({
  //             uid: attachment.ID,
  //             name: attachment.DN,
  //             status: "done",
  //             url: `${baseURL}/attachments/${attachment.FN}`,
  //           });
  //         });
  //         tempData[dataKeys[i]] = {
  //           ...tempData[dataKeys[i]],
  //           [key]: attachmentArray,
  //         };
  //       } else {
  //         tempData[dataKeys[i]] = {
  //           ...tempData[dataKeys[i]],
  //           [key]: [],
  //         };
  //       }
  //     } else if (staticFields.includes(key)) {
  //       let newValue = staticoptions[key][value.DV]?.TITLE;
  //       let bgColor = staticoptions[key][value.DV]?.BC;
  //       let order = staticoptions[key][value.DV]?.ORDER;
  //       tempData[dataKeys[i]] = {
  //         ...tempData[dataKeys[i]],
  //         [key]: {
  //           view: newValue,
  //           bgColor: bgColor,
  //           order: order,
  //         },
  //         [`${key}-server`]: value.DV,
  //         [`${key}-US`]: value.US,
  //       };
  //     } else if (foreignFields.includes(key)) {
  //       let newValue = fkvalues[key][value.DV]?.view;
  //       tempData[dataKeys[i]] = {
  //         ...tempData[dataKeys[i]],
  //         [key]: newValue,
  //         [`${key}-server`]: value.DV,
  //         [`${key}-US`]: value.US,
  //       };
  //     } else if (multipleForeignFields.includes(key)) {
  //       let newValue = value.DV?.split(",") ?? [];
  //       newValue.sort();
  //       var str = "";
  //       var serverString = "";
  //       newValue
  //         .filter((item) => item !== "")
  //         .forEach((item) => {
  //           if (str !== "") {
  //             str += ", ";
  //           }
  //           str += fkvalues[key][item]?.view;

  //           if (serverString !== "") {
  //             serverString += ",";
  //           }
  //           serverString += item;
  //         });
  //       tempData[dataKeys[i]] = {
  //         ...tempData[dataKeys[i]],
  //         [key]: str,
  //         [`${key}-server`]: serverString,
  //         [`${key}-US`]: value.US,
  //       };
  //     } else if (dependentFields.includes(key)) {
  //       const parentFieldID = fields
  //         .filter((field) => field.F_ID === key)
  //         .map((item) => item.F_PARENTFIELDID);

  //       const DVArray =
  //         rows[rowID][parentFieldID]?.DV.split(",").filter((item) => item) ??
  //         [];

  //       let str = "";
  //       let serverStr = "";
  //       DVArray.map((item) => {
  //         if (str !== "") {
  //           str += ", ";
  //         }
  //         str += fkvalues[key]?.[item]?.view ?? "";

  //         if (serverStr !== "") {
  //           serverStr += ",";
  //         }
  //         serverStr += item;
  //       });

  //       const finalValue = str;
  //       const finalServerValue = serverStr;

  //       tempData[dataKeys[i]] = {
  //         ...tempData[dataKeys[i]],
  //         [key]: finalValue,
  //         [`${key}-server`]: finalServerValue,
  //         [`${key}-US`]: value.US,
  //       };
  //     } else if (key === "LM") {
  //       tempData[dataKeys[i]] = { ...tempData[dataKeys[i]], [key]: value };
  //     } else if (key === "ROWID") {
  //       tempData[dataKeys[i]] = { ...tempData[dataKeys[i]], [key]: value };
  //     } else {
  //       tempData[dataKeys[i]] = {
  //         ...tempData[dataKeys[i]],
  //         [key]: value.DV,
  //         [`${key}-US`]: value.US,
  //       };
  //     }
  //   }
  // }

  // tempData = tempData.filter((item) => item);

  //const tempData = testData;

  return modifiedData;
};

export default createData;
