import htmlCodesRemover from "../../../ultimateTable/helpers/htmlCodesRemover";
import chooseEventDisplay from "./chooseEventDisplay";
import choosePeriodicityDisplay from "./choosePeriodicityDisplay";
import chooseSentDateDisplay from "./chooseSentDateDisplay";
import chooseTypeDisplay from "./chooseTypeDisplay";

const emailFilterDataOnSearch = (
  allData,
  change,
  modules,
  titles,
  commonTitles,
  notifyfields,
  templates
) => {
  let tempData = [];
  const replacements = [
    { key: "ί", newKey: "ι" },
    { key: "έ", newKey: "ε" },
    { key: "ό", newKey: "ο" },
    { key: "ή", newKey: "η" },
    { key: "ύ", newKey: "υ" },
    { key: "ώ", newKey: "ω" },
    { key: "ά", newKey: "α" },
    { key: "ς", newKey: "σ" },
  ];

  const notSearchableKeys = [
    "N_ID",
    "N_ACTIVE",
    "N_EXPIREINDICATION",
    "N_FILTER",
    "N_INFORMATION_FIELDIDS",
    "N_LASTMODIFIED",
    "N_LASTUSER",
    "N_TRIGGER_FIELDID",
    "N_MONTH_DAY",
    "N_WEEK_DAY",
  ];

  if (Object.keys(allData).length) {
    Object.entries(allData).forEach(([key, dataValue]) => {
      let isIncluded = false;
      Object.keys(dataValue)
        .filter((key) => !notSearchableKeys?.includes(key))
        .forEach((key) => {
          if (change) {
            change = change.toLowerCase();
            replacements.forEach(({ key, newKey }) => {
              var re = new RegExp(key, "g");
              change = change.replace(re, newKey);
            });
            let valueToTest = "";
            if (key === "N_MODULEID") {
              valueToTest = htmlCodesRemover(modules?.[dataValue?.N_MODULEID]);
              replacements.forEach(({ key, newKey }) => {
                var re = new RegExp(key, "g");
                valueToTest = valueToTest?.toLowerCase().replace(re, newKey);
              });
              if (valueToTest?.includes(change)) {
                isIncluded = true;
              }
            } else if (key === "N_TYPE") {
              valueToTest = chooseTypeDisplay(dataValue?.N_TYPE, titles);
              replacements.forEach(({ key, newKey }) => {
                var re = new RegExp(key, "g");
                valueToTest = valueToTest?.toLowerCase().replace(re, newKey);
              });

              if (valueToTest?.includes(change)) {
                isIncluded = true;
              }
            } else if (key === "TRIGGERFIELDTITLE") {
              valueToTest = htmlCodesRemover(dataValue?.TRIGGERFIELDTITLE);
              replacements.forEach(({ key, newKey }) => {
                var re = new RegExp(key, "g");
                valueToTest = valueToTest?.toLowerCase().replace(re, newKey);
              });
              if (valueToTest?.includes(change)) {
                isIncluded = true;
              }
            } else if (key === "N_PERIOD_NUM") {
              valueToTest = choosePeriodicityDisplay(
                dataValue?.N_PERIOD_NUM,
                dataValue?.N_PERIOD_TYPE,
                titles,
                commonTitles,
                dataValue?.N_TYPE
              );
              replacements.forEach(({ key, newKey }) => {
                var re = new RegExp(key, "g");
                valueToTest = valueToTest?.toLowerCase().replace(re, newKey);
              });
              if (valueToTest?.includes(change)) {
                isIncluded = true;
              }
            } else if (key === "N_PERIOD_TYPE") {
              if (dataValue?.N_PERIOD_TYPE === "W") {
                valueToTest = chooseSentDateDisplay(
                  dataValue?.N_PERIOD_TYPE,
                  dataValue?.N_WEEK_DAY,
                  titles
                );
                replacements.forEach(({ key, newKey }) => {
                  var re = new RegExp(key, "g");
                  valueToTest = valueToTest?.toLowerCase().replace(re, newKey);
                });
              }

              if (dataValue?.N_PERIOD_TYPE === "M") {
                valueToTest = chooseSentDateDisplay(
                  dataValue?.N_PERIOD_TYPE,
                  dataValue?.N_MONTH_DAY,
                  titles
                );
                replacements.forEach(({ key, newKey }) => {
                  var re = new RegExp(key, "g");
                  valueToTest = valueToTest?.toLowerCase().replace(re, newKey);
                });
              }
              if (valueToTest?.includes(change)) {
                isIncluded = true;
              }
            } else if (key === "N_EVENT") {
              valueToTest = chooseEventDisplay(
                dataValue?.N_EVENT ?? "",
                titles
              );
              replacements.forEach(({ key, newKey }) => {
                var re = new RegExp(key, "g");
                valueToTest = valueToTest?.toLowerCase().replace(re, newKey);
              });
              if (valueToTest?.includes(change)) {
                isIncluded = true;
              }
            } else if (key === "N_NOTIFY_FIELD") {
              valueToTest = htmlCodesRemover(
                notifyfields?.[dataValue?.N_MODULEID]?.filter(
                  (item) => item?.ID === dataValue?.N_ID
                )?.[0]?.T_TITLE
              );
              replacements.forEach(({ key, newKey }) => {
                var re = new RegExp(key, "g");
                valueToTest = valueToTest?.toLowerCase().replace(re, newKey);
              });

              if (valueToTest?.includes(change)) {
                isIncluded = true;
              }
            } else if (key === "N_TEMPLATEID") {
              valueToTest = templates?.[dataValue?.N_TEMPLATEID];
              replacements.forEach(({ key, newKey }) => {
                var re = new RegExp(key, "g");
                valueToTest = valueToTest?.toLowerCase().replace(re, newKey);
              });
              if (valueToTest?.includes(change)) {
                isIncluded = true;
              }
            } else if (key === "INFOFIELDTITLES") {
              valueToTest = htmlCodesRemover(dataValue?.INFOFIELDTITLES);
              replacements.forEach(({ key, newKey }) => {
                var re = new RegExp(key, "g");
                valueToTest = valueToTest?.toLowerCase().replace(re, newKey);
              });

              if (valueToTest?.includes(change)) {
                isIncluded = true;
              }
            } else if (key === "T_TITLE") {
              valueToTest = htmlCodesRemover(dataValue?.T_TITLE);
              replacements.forEach(({ key, newKey }) => {
                var re = new RegExp(key, "g");
                valueToTest = valueToTest?.toLowerCase().replace(re, newKey);
              });

              if (valueToTest?.includes(change)) {
                isIncluded = true;
              }
            } else {
              valueToTest = dataValue?.[key];
              replacements.forEach(({ key, newKey }) => {
                var re = new RegExp(key, "g");
                valueToTest = valueToTest?.toLowerCase().replace(re, newKey);
              });

              if (valueToTest?.includes(change)) {
                isIncluded = true;
              }
            }
          }
        });
      isIncluded && tempData.push(dataValue);
    });
  }

  return tempData;
};

export default emailFilterDataOnSearch;
