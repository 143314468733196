const StaticListType = ({ value }) => {
  let newValue = value ?? null;
  if (typeof value === "object") {
    newValue = value.view ?? "";
  }
  newValue = newValue ?? "";
  return (
    <p
      className="p-context"
      style={
        value?.bgColor !== "#ffffff" &&
        value?.bgColor !== "#fff" &&
        value?.bgColor !== "white" &&
        value?.bgColor !== "rgb(255, 255, 255)"
          ? { backgroundColor: value?.bgColor }
          : null
      }
    >
      {newValue}
    </p>
  );
};

export default StaticListType;
