const filterUserRoleRightsOnSearch = (allData, change) => {
  let tempData = [];
  const replacements = [
    { key: "ί", newKey: "ι" },
    { key: "έ", newKey: "ε" },
    { key: "ό", newKey: "ο" },
    { key: "ή", newKey: "η" },
    { key: "ύ", newKey: "υ" },
    { key: "ώ", newKey: "ω" },
    { key: "ά", newKey: "α" },
    { key: "ς", newKey: "σ" },
  ];

  if (Object.keys(allData).length) {
    Object.entries(allData).forEach(([key, value]) => {
      let isIncluded = false;
      Object.keys(value).forEach((key) => {
        if (change) {
          change = change.toLowerCase();
          replacements.forEach(({ key, newKey }) => {
            var re = new RegExp(key, "g");
            change = change.replace(re, newKey);
          });
          let valueToTest = value?.name;
          replacements.forEach(({ key, newKey }) => {
            var re = new RegExp(key, "g");
            valueToTest = valueToTest.toLowerCase().replace(re, newKey);
          });
          if (valueToTest.includes(change)) {
            isIncluded = true;
          }
        }
      });
      isIncluded && tempData.push(value);
    });
  }

  return tempData;
};

export default filterUserRoleRightsOnSearch;
