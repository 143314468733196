import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import "./reports.css";
import ReactTooltip from "react-tooltip";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  useCommonTitlesStore,
  useAuthStore,
  useModuleStore,
} from "../../../../store/useStores";
import { ReportModal } from "./modal/ReportModal";
import moment from "moment";
import { convertDateFormat } from "../newRegister/helpers/converters";
import apiReport from "../../../../api/endpoints/apiReport";
import apiDownload from "../../../../api/endpoints/apiDownload";
import { message } from "antd";
import { useEffect } from "react";

const Reports = ({ moduleID, columns, title }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const baseURL = useAuthStore((state) => state.baseURL);

  const [reportModalVisible, setReportModalVisible] = useState(false);

  const showReportModal = () => {
    setReportModalVisible(true);
  };

  const bannerTextColor = useAuthStore((state) => state.bannerTextColor);
  const dateFormat = useAuthStore((state) => state.dateFormat);
  let dateFormatToUse = convertDateFormat(dateFormat);

  let today = new Date();
  today = moment(today, dateFormatToUse).format(dateFormatToUse);

  const [changes, setChanges] = useState({});

  const orderBy = useModuleStore((state) => state.orderBy);
  const orderDirection = useModuleStore((state) => state.orderDirection);

  useEffect(() => {
    let orderDir = "";
    if (orderDirection?.[moduleID] === "ASC") {
      orderDir = "A";
    }

    if (orderDirection?.[moduleID] === "DESC") {
      orderDir = "D";
    }

    setChanges({
      reporttype: "L",
      docformat: "P",
      docsize: "4",
      orientation: "L",
      filename: title.includes(" ") ? title.split(" ").join("_") : title,
      reporttitle: title,
      fontsize: "10",
      lastupdate: today,
      revisionnumber: "",
      ordercol: orderBy?.[moduleID] ?? "",
      orderdir: orderDir,
    });
  }, [title, orderBy, orderDirection, moduleID]);

  const updateReportParams = (id, value) => {
    setChanges({ ...changes, [id]: value });
  };

  const restoreChanges = () => {
    let orderDir = "";
    if (orderDirection === "ASC") {
      orderDir = "A";
    }

    if (orderDirection === "DESC") {
      orderDir = "D";
    }

    setChanges({
      reporttype: "L",
      docformat: "P",
      docsize: "4",
      orientation: "L",
      filename: title.includes(" ") ? title.split(" ").join("_") : title,
      reporttitle: title,
      fontsize: "10",
      lastupdate: today,
      revisionnumber: "",
      ordercol: orderBy,
      orderdir: orderDir,
    });
  };

  //console.log("Reports: changes: ", changes);

  const onSave = async () => {
    const response = await apiReport(baseURL, moduleID, changes);
    console.log("Reports: onSave: response: ", response);
    if (response.STATUS === "Success") {
      let suffix = "pdf";
      if (changes.docformat === "E") {
        suffix = "xlsx";
      }
      const downloadResponse = await apiDownload(
        baseURL,
        response.MESSAGE,
        changes.filename,
        suffix
      );
      console.log("Reports: onSuccess: downloadResponse: ", downloadResponse);
      restoreChanges();
      setReportModalVisible(false);
      //message.success("Επιτυχής έκδοση αναφοράς", [3]);
    } else {
      restoreChanges();
      setReportModalVisible(false);
      //message.error("Παρουσιάστηκε σφάλμα κατά την έκδοση της αναφοράς", [3]);
    }
  };

  const onCancel = () => {
    restoreChanges();
    setReportModalVisible(false);
  };

  return (
    <div className="data-reports">
      <a data-tip={`${commonTitles.report}`}>
        <Button sx={{ color: bannerTextColor }} onClick={showReportModal}>
          {<PictureAsPdfIcon />}
        </Button>
      </a>
      <ReactTooltip place="bottom" />
      <ReportModal
        title={title}
        today={today}
        visible={reportModalVisible}
        columns={columns}
        updateReportParams={updateReportParams}
        onSave={onSave}
        onCancel={onCancel}
      />
    </div>
  );
};

export default Reports;
