import Flex from "../../../../../helpers/components/Flex";
import htmlCodesRemover from "../../htmlCodesRemover";

const TextType = ({ value, config, id }) => {
  if (config === "alerts" && id === "info") {
    const newValue = value.map((item, index) =>
      item.title ? (
        <Flex key={index} flexDirection={"row"} style={{ gap: "0.5rem" }}>
          <b>{item.title}:</b>
          <p>{item.value}</p>
        </Flex>
      ) : null
    );

    return newValue ?? "";
  }

  let shortValue = htmlCodesRemover(value, 50, "table") ?? "";

  return shortValue;

  // if (value && value?.length > 50) {
  //   shortValue = value.slice(0, 50) + "...";
  // }

  // if (value && value.includes("&#39;")) {
  //   let splitValue = value
  //     .split("&#39;")
  //     .filter((item) => item !== "")
  //     .join("'")
  //     .split("&#34;")
  //     .filter((item) => item !== "")
  //     .join(`"`);

  //   if (splitValue && splitValue.includes("\n")) {
  //     let newSplitValue = splitValue.split("\n").filter((item) => item !== "");
  //     const newValue = newSplitValue.map((item) => (
  //       <p key={item}>
  //         {item}
  //         <br />
  //       </p>
  //     ));
  //     if (newValue[0]?.props.children[0]?.length > 50) {
  //       return newValue[0]?.props.children[0].slice(0, 50) + "...";
  //     } else {
  //       return newValue;
  //     }
  //   } else if (splitValue && splitValue.includes("&#10;")) {
  //     let newSplitValue = splitValue
  //       .split("&#10;")
  //       .filter((item) => item !== "");
  //     const newValue = newSplitValue.map((item) => (
  //       <p key={item}>
  //         {item}
  //         <br />
  //       </p>
  //     ));
  //     if (newValue[0]?.props.children[0]?.length > 50) {
  //       return newValue[0]?.props.children[0].slice(0, 50) + "...";
  //     } else {
  //       return newValue;
  //     }
  //   }
  //   if (splitValue.length > 50) {
  //     return splitValue.slice(0, 50) + "...";
  //   } else {
  //     return splitValue;
  //   }
  // } else if (value && value.includes("\n")) {
  //   let newSplitValue = value.split("\n").filter((item) => item !== "");
  //   const newValue = newSplitValue.map((item) => (
  //     <p key={item}>
  //       {item}
  //       <br />
  //     </p>
  //   ));
  //   if (newValue[0]?.props.children[0]?.length > 50) {
  //     return newValue[0]?.props.children[0].slice(0, 50) + "...";
  //   } else {
  //     return newValue;
  //   }
  // } else if (value && value.includes("&#10;")) {
  //   let newSplitValue = value.split("&#10;").filter((item) => item !== "");
  //   const newValue = newSplitValue.map((item) => (
  //     <p key={item}>
  //       {item}
  //       <br />
  //     </p>
  //   ));
  //   if (newValue[0]?.props.children[0]?.length > 50) {
  //     return newValue[0]?.props.children[0].slice(0, 50) + "...";
  //   } else {
  //     return newValue;
  //   }
  // } else {
  //   return shortValue;
  // }
};

export default TextType;
