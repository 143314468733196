import { Input, Modal, Select, Button, Checkbox, Spin, message } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import apiGetUserRolesRights from "../../../api/endpoints/apiGetUserRoleRights";
import Flex from "../../../helpers/components/Flex";
import { useCommonTitlesStore, useAuthStore } from "../../../store/useStores";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableBody, TableHead } from "@mui/material";
import { FieldModal } from "./FieldModal";
import axios from "axios";
import htmlCodesRemover from "../../ultimateTable/helpers/htmlCodesRemover";
import UserRolesRightsSearch from "./UserRolesRightsSearch";
import filterUserRoleRightsOnSearch from "./helpers/filterUserRoleRightsOnSearch";

const { Option } = Select;

const UserRolesRightsModalContent = ({
  userRoleRights,
  setUserRoleRights,
  allUserRoleRights,
  headers,
  titles,
  updateUserRoleRights,
  allChecked,
  selectAll,
  roleID,
  fetchDataAgain,
}) => {
  const [fieldsModalVisible, setFieldsModalVisible] = useState(false);
  const [moduleID, setModuleID] = useState(null);
  const baseURL = useAuthStore((state) => state.baseURL);

  const saveFieldRights = async (fieldRights) => {
    const url = `${baseURL}/User_role_module_fields_api/save`;

    let fields = {};
    Object.values(fieldRights).forEach((item) => {
      fields = {
        ...fields,
        [item.F_ID]: { V: item.V, I: item.I, U: item.U, E: item.E },
      };
    });

    try {
      const response = await axios.post(
        url,
        { id: roleID, moduleid: moduleID, fields: fields },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log(
        "User_role_module_fields_api/save: response: ",
        response.data
      );

      if (response.data.STATUS === "Success") {
        fetchDataAgain();
        setFieldsModalVisible(false);
      } else {
        message(response.data.MESSAGE, [3]);
      }
    } catch (err) {}
  };
  const cancelFieldModal = () => {
    setFieldsModalVisible(false);
  };

  const updateSearchValue = (change) => {
    const tempData = filterUserRoleRightsOnSearch(allUserRoleRights, change);

    if (change) {
      setUserRoleRights([...tempData]);
    } else {
      setUserRoleRights(allUserRoleRights);
    }
  };

  return (
    <Flex
      flexDirection={"column"}
      style={{ width: "100%", justifyContent: "flex-end", marginTop: 15 }}
    >
      <UserRolesRightsSearch updateSearchValue={updateSearchValue} />

      <TableContainer
        component={Paper}
        style={{ maxHeight: "70vh" }}
        className="testing_table"
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  style={{ fontWeight: 700, color: "rgb(8, 8, 46)" }}
                  key={header.id}
                >
                  <Flex flexDirection={"row"} style={{ gap: "0.5rem" }}>
                    {index !== 0 && index !== headers.length - 1 && (
                      <Checkbox
                        checked={allChecked[header.id]}
                        onChange={(e) => selectAll(header.id, e.target.checked)}
                      />
                    )}
                    {header.title}
                  </Flex>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {userRoleRights.map((item) => (
              <TableRow key={item.M_ID}>
                {headers.map(({ id }, index) => (
                  <>
                    {index === 0 ? (
                      <TableCell key={id}>
                        {htmlCodesRemover(item[id])}
                      </TableCell>
                    ) : index < headers.length - 1 ? (
                      <TableCell key={id} align="center">
                        <Checkbox
                          checked={item[id]}
                          onChange={(e) =>
                            updateUserRoleRights(
                              item.M_ID,
                              id,
                              e.target.checked
                            )
                          }
                        />
                      </TableCell>
                    ) : (
                      <TableCell key={id}>
                        <Button
                          shape="round"
                          className="dropdown"
                          onClick={() => {
                            setFieldsModalVisible(true);
                            setModuleID(item.M_ID);
                          }}
                          style={{
                            backgroundColor: "rgb(8, 8, 46)",
                            color: "#fff",
                          }}
                        >
                          {titles["MO|10|FIELDLIST"]}
                        </Button>
                      </TableCell>
                    )}
                  </>
                ))}
              </TableRow>
            ))}
            {fieldsModalVisible && (
              <FieldModal
                visible={fieldsModalVisible}
                title={titles["MO|10|FIELDLIST"]}
                onSave={saveFieldRights}
                onCancel={cancelFieldModal}
                roleID={roleID}
                moduleID={moduleID}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Flex>
  );
};

export const UserRolesRightsModal = ({
  visible,
  title,
  onSave,
  onCancel,
  id,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const baseURL = useAuthStore((state) => state.baseURL);
  const language = useAuthStore((state) => state.language);
  const projectID = useAuthStore((state) => state.projectID);
  const [userRoleRights, setUserRoleRights] = useState([]);
  const [allUserRoleRights, setAllUserRoleRights] = useState([]);
  const [titles, setTitles] = useState({});
  const [headers, setHeaders] = useState([]);
  const [fetchAgain, setFetchAgain] = useState(false);
  const [allChecked, setAllChecked] = useState({
    V: false,
    I: false,
    U: false,
    D: false,
  });

  useEffect(async () => {
    const { modules, moduletitles } = await apiGetUserRolesRights(baseURL, id);
    setUserRoleRights(modules);
    setAllUserRoleRights(modules);
    setTitles(moduletitles);
    setHeaders([
      { id: "name", title: moduletitles["FD|255|TITLE"] },
      { id: "V", title: moduletitles["FD|256|TITLE"] },
      { id: "I", title: moduletitles["FD|257|TITLE"] },
      { id: "U", title: moduletitles["FD|258|TITLE"] },
      { id: "D", title: moduletitles["FD|259|TITLE"] },
      { id: "fields", title: "" },
    ]);
    let viewAll = true;
    let insertAll = true;
    let updateAll = true;
    let deleteAll = true;
    modules.forEach((item) => {
      if (!item.V) {
        viewAll = false;
      }
      if (!item.I) {
        insertAll = false;
      }
      if (!item.U) {
        updateAll = false;
      }
      if (!item.D) {
        deleteAll = false;
      }
    });
    setAllChecked({ V: viewAll, I: insertAll, U: updateAll, D: deleteAll });
  }, [id, language, projectID, fetchAgain]);

  const fetchDataAgain = () => setFetchAgain(!fetchAgain);

  const updateUserRoleRights = (rowID, id, value) => {
    const newState = userRoleRights.map((item) => {
      if (item.M_ID === rowID) {
        return { ...item, [id]: value };
      }

      return item;
    });

    setUserRoleRights(newState);
  };

  const selectAll = (id, value) => {
    const newState = userRoleRights.map((item) => {
      return { ...item, [id]: value };
    });

    setUserRoleRights(newState);
    setAllChecked({ ...allChecked, [id]: value });
  };

  return (
    <Modal
      key={`userRoleRights-modal`}
      mask={false}
      maskClosable={false}
      open={visible}
      style={{
        minWidth: "90vw",
      }}
      bodyStyle={{ paddingLeft: 80, paddingRight: 80 }}
      title={title}
      onOk={() => onSave(userRoleRights)}
      onCancel={() => onCancel()}
      footer={[
        <Button key="back" onClick={() => onCancel()}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,
        <Button
          key="submit"
          type="primary"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          onClick={() => onSave(userRoleRights)}
        >
          {commonTitles["CO|1|SAVEBTN"]}
        </Button>,
      ]}
    >
      {userRoleRights.length ? (
        <UserRolesRightsModalContent
          userRoleRights={userRoleRights}
          setUserRoleRights={setUserRoleRights}
          allUserRoleRights={allUserRoleRights}
          headers={headers}
          titles={titles}
          updateUserRoleRights={updateUserRoleRights}
          allChecked={allChecked}
          selectAll={selectAll}
          roleID={id}
          fetchDataAgain={fetchDataAgain}
        />
      ) : (
        <Spin />
      )}
    </Modal>
  );
};
