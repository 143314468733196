import { useState } from "react";
import {
  useAuthStore,
  useCommonTitlesStore,
  useAlertStore,
  useModuleStore,
} from "../../../../store/useStores";
import axios from "axios";
import EditAction from "./EditAction";
import { useEffect } from "react";
import createData from "../../../module/helpers/createData";
import htmlCodesRemover from "../../helpers/htmlCodesRemover";

const EditAlertAction = ({ row, privileges, updateRow }) => {
  const baseURL = useAuthStore((state) => state.baseURL);

  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const [recordData, setRecordData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [staticOptions, setStaticOptions] = useState({});
  const [foreignKeyValues, setForeignKeyValues] = useState({});
  const [canAlertUpdate, setCanAlertUpdate] = useState(false);

  const alerts = useAlertStore((state) => state.alerts);
  const alertData = alerts.alerts.filter((item) => item.V_ID === row.key);
  const alertTypes = alerts.alerttypes;
  const moduleID = alertTypes?.[alertData?.[0]?.A_ID]?.MODULEID;

  const dateTypes = useModuleStore((state) => state.dateTypes);

  useEffect(async () => {
    const { rows, fields, staticoptions, fkvalues, canupdate } =
      await getData();

    const tempData = createData(
      rows,
      fields,
      fkvalues,
      staticoptions,
      baseURL,
      dateTypes
    );
    const newTempData = Object.values(tempData).filter((item) => item);
    setRecordData(newTempData);
    setOriginalData(tempData);

    const tempColumns = fields.map((item) => ({
      ...item,
      T_TITLE: htmlCodesRemover(item.T_TITLE) ?? item.T_TITLE,
    }));
    setColumns(tempColumns);

    setForeignKeyValues(fkvalues);
    setStaticOptions(staticoptions);
    setCanAlertUpdate(canupdate);
  }, []);

  const getData = async () => {
    const url = `${baseURL}/Data_api/getrecord`;

    try {
      const response = await axios.post(
        url,
        { moduleid: moduleID, rowid: row.rowID },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Data_api/getrecord: response: ", response?.data);
      return response?.data;
    } catch (err) {
      //message.error(err, [3]);
      //displayResponseMessage(commonTitles["CO|1|ERR500"], 8, "error");
    }
  };

  return (
    <EditAction
      row={{ ...recordData[0], rowID: row.rowID, ROWID: row.rowID }}
      rowID={row.rowID}
      moduleID={moduleID}
      columns={columns}
      title={"Alert"}
      privileges={privileges}
      canUpdate={canAlertUpdate}
      foreignKeyValues={foreignKeyValues}
      staticOptions={staticOptions}
      dataToUse={recordData}
      originalData={originalData}
      updateRow={updateRow}
      //autoCompletedFields={autoCompletedFields}
    />
  );
};

export default EditAlertAction;
