import "./css/emailConfig.css";
import { Layout, Card, Spin } from "antd";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { useAuthStore, useModTitlesStore } from "../../../store/useStores";
import Navigation from "../../navigation/Navigation";
import Flex from "../../../helpers/components/Flex";
import filterDataOnSearch from "../helpers/filterDataOnSearch";
import { DeleteModal } from "./deleteModals";
import axios from "axios";
import { useCommonTitlesStore } from "../../../store/useStores";
import displayResponseMessage from "../../../helpers/functions/displayResponseMessage";
import EmailConfigHead from "./EmailConfigHead";
import EmailConfigBody from "./EmailConfigBody";
import EmailConfigSettings from "./EmailConfigSettings";
import apiGetEmailAlertsList from "../../../api/endpoints/apiGetEmailAlertsList";
import createHeaders from "./helpers/createHeaders";
import BackgroundWrapper from "../../../helpers/components/BackgroundWrapper";
import AdministrationBanner from "../administrationBanner/AdministrationBanner";
import TableWrapper from "../../../helpers/components/TableWrapper";
import emailFilterDataOnSearch from "./helpers/emailFilterDataOnSearch";

const { Content } = Layout;

const EmailConfig = () => {
  const modTitles = useModTitlesStore((state) => state.modTitles);
  const baseURL = useAuthStore((state) => state.baseURL);
  const language = useAuthStore((state) => state.language);
  const projectID = useAuthStore((state) => state.projectID);
  const coreChange = useAuthStore((state) => state.coreChange);
  const setCoreChange = useAuthStore((state) => state.setCoreChange);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const [data, setData] = useState([]);
  const [bodyData, setBodyData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [modules, setModules] = useState({});
  const [titles, setTitles] = useState({});
  const [fields, setFields] = useState({});
  const [staticOptions, setStaticOptions] = useState({});
  const [notifyFields, setNotifyFields] = useState({});
  const [templates, setTemplates] = useState({});

  const [modalVisible, setModalVisible] = useState(false);
  const [newItemModalVisible, setNewItemModalVisible] = useState(false);
  const [modalData, setModalData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [itemToDelete, setItemToDelete] = useState({});

  const [filtersChanged, setFiltersChanged] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    const serverData = await apiGetEmailAlertsList(baseURL);

    const tempEmailAlerts =
      serverData?.notifications?.map((item) => {
        let newItem = { ...item };
        if (item?.INFOFIELDTITLES?.includes("<br>")) {
          newItem = {
            ...newItem,
            INFOFIELDTITLES: item?.INFOFIELDTITLES?.split("<br>")?.join(", "),
          };
        }

        if (item?.N_EVENT?.includes(",")) {
          newItem = {
            ...newItem,
            N_EVENT: item.N_EVENT.split(","),
          };
        } else {
          if (item?.N_EVENT) {
            newItem = { ...newItem, N_EVENT: [item.N_EVENT] };
          } else {
            newItem = { ...newItem, N_EVENT: [] };
          }
        }

        if (item?.N_NOTIFY_FIELD?.includes(",")) {
          newItem = {
            ...newItem,
            N_NOTIFY_FIELD: item.N_NOTIFY_FIELD.split(","),
          };
        } else {
          if (item?.N_NOTIFY_FIELD) {
            newItem = { ...newItem, N_NOTIFY_FIELD: [item.N_NOTIFY_FIELD] };
          } else {
            newItem = { ...newItem, N_NOTIFY_FIELD: [] };
          }
        }

        return newItem;
      }) ?? [];

    setData(tempEmailAlerts);
    setBodyData(tempEmailAlerts);

    const tempHeaders = createHeaders(serverData?.moduletitles);
    setHeaders(tempHeaders);

    setModules(serverData?.modules ?? {});
    setTitles(serverData?.moduletitles ?? {});
    setFields(serverData?.fields ?? {});
    setStaticOptions(serverData?.staticoptions ?? {});
    setNotifyFields(serverData?.notifyfields ?? {});
    setTemplates(serverData?.templates ?? {});
  };

  useEffect(() => {
    fetchData();
    setIsLoading(false);
  }, [projectID, language, filtersChanged]);

  const reverseFiltersChanged = () => {
    setFiltersChanged(!filtersChanged);
  };

  const showModal = (param, config) => {
    if (config === "edit") {
      setModalData(param);
      setModalVisible(true);
    }

    if (config === "new") {
      setNewItemModalVisible(true);
    }
  };

  const updateData = (config, changes, id) => {
    if (config === "edit") {
      setModalVisible(false);
      const tempData = bodyData.map((item) => {
        if (item.N_ID === id) {
          return { ...item, ...changes };
        } else {
          return item;
        }
      });
      setBodyData(tempData);
      setData(tempData);
    }

    if (config === "new") {
      setNewItemModalVisible(false);
      setBodyData([...bodyData, changes]);
      setData([...data, changes]);
    }
  };

  const handleCancel = (config) => {
    if (config === "edit") {
      setModalVisible(false);
    }

    if (config === "new") {
      setNewItemModalVisible(false);
    }
  };

  const showDeleteModal = (id) => {
    setDeleteModalVisible(true);
    setItemToDelete(id);
  };
  const cancelDeleteModal = () => setDeleteModalVisible(false);

  const handleDelete = async (id) => {
    const url = `${baseURL}/app_notifications_api/delete`;

    try {
      const response = await axios.post(
        url,
        {
          id: id,
        },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("app_notifications_api/delete: response: ", response?.data);

      if (response?.data?.STATUS === "Success") {
        displayResponseMessage(commonTitles["CO|1|REGMSG1"], 3, "success");
        setBodyData(bodyData.filter((item) => item.N_ID !== id));
        setDeleteModalVisible(false);
        setModalVisible(false);
        setCoreChange(!coreChange);
      } else {
        const responseMsg = response?.data?.MESSAGE ?? {};
        let template = commonTitles[responseMsg.ID];

        Object.entries(responseMsg)
          .filter(([key]) => key !== "ID")
          .forEach(([key, value]) => {
            var re = new RegExp(key, "g");
            template = template.replace(re, value);
          });

        displayResponseMessage(template, 5, "warning");
      }
    } catch (err) {
      displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
    }
  };

  const updateSearchValue = (change) => {
    const tempData = emailFilterDataOnSearch(
      data,
      change,
      modules,
      titles,
      commonTitles,
      notifyFields,
      templates
    );

    if (change) {
      setBodyData(tempData);
    } else {
      setBodyData(data);
    }
  };

  return (
    <Flex flexDirection={"column"} key={data.length}>
      <Navigation />
      <BackgroundWrapper>
        <AdministrationBanner
          title={`/ ${modTitles.emailsConfig}`}
          updateSearchValue={updateSearchValue}
          showSearch={true}
        />

        <TableWrapper>
          <Layout
            style={{
              borderRadius: 10,
              overflow: "hidden",
              height: "max-content",
              width: "100%",
            }}
          >
            <EmailConfigSettings
              key={"new-item"}
              headers={headers}
              titles={titles}
              modules={modules}
              newItemModalVisible={newItemModalVisible}
              showModal={showModal}
              updateData={updateData}
              handleDelete={handleDelete}
              handleCancel={handleCancel}
              fields={fields}
              notifyFields={notifyFields}
              templates={templates}
            />
            <Layout className="site-layout">
              <Content
                style={{
                  paddingBottom: 15,
                  paddingLeft: 20,
                  paddingRight: 20,
                  minHeight: "max-content",
                  backgroundColor: "transparent",
                }}
              >
                <TableContainer
                  component={Paper}
                  style={{ maxHeight: "70vh" }}
                  className="testing_table"
                >
                  {isLoading ? (
                    <Card
                      style={{
                        borderRadius: "10px",
                        marginBottom: "10px",
                        width: "98.5vw",
                        display: "flex",
                        justifyContent: "center",
                        height: "85vh",
                      }}
                    >
                      <Spin size="large" style={{ marginTop: "15rem" }} />
                    </Card>
                  ) : (
                    <Table stickyHeader>
                      <EmailConfigHead headers={headers} />
                      <EmailConfigBody
                        key={`body`}
                        data={bodyData}
                        headers={headers}
                        titles={titles}
                        modules={modules}
                        modalVisible={modalVisible}
                        modalData={modalData}
                        showModal={showModal}
                        updateData={updateData}
                        handleDelete={handleDelete}
                        handleCancel={handleCancel}
                        fields={fields}
                        showDeleteModal={showDeleteModal}
                        reverseFiltersChanged={reverseFiltersChanged}
                        notifyFields={notifyFields}
                        templates={templates}
                      />
                    </Table>
                  )}
                </TableContainer>
              </Content>
            </Layout>
          </Layout>
        </TableWrapper>
      </BackgroundWrapper>
      {deleteModalVisible && (
        <DeleteModal
          visible={deleteModalVisible}
          selectedItems={[itemToDelete]}
          onDelete={handleDelete}
          onCancel={cancelDeleteModal}
        />
      )}
    </Flex>
  );
};

export default EmailConfig;
