import Flex from "../../../../../helpers/components/Flex";
import { Progress } from "antd";

const RenderProgress = ({ value, inMiniCard }) => {
  let percentageValueParts = "";
  if (typeof value === "object") {
    percentageValueParts = value?.view?.split("%");
  } else {
    percentageValueParts = value?.split("%");
  }

  return (
    <Flex flexDirection={"row"}>
      <Progress
        type="circle"
        format={() => (
          <div style={{ color: inMiniCard && "white" }}>
            {percentageValueParts?.[0] ?? "0" + "%"}
          </div>
        )}
        size={60}
        strokeColor={{
          "0%": "#108ee9",
          "100%": "#87d068",
        }}
        trailColor={inMiniCard && "#FAF9F6"}
        percent={percentageValueParts?.[0]?.toString()}
      />
      <p
        className="circle-progress-info"
        style={{ color: inMiniCard && "white" }}
      >
        {percentageValueParts?.[1]}
      </p>
    </Flex>
  );
};

export default RenderProgress;
